import React from 'react'
import * as styles from './index.module.less'

const Modal = ({ isOpen, children }) => {
  if (!isOpen) return null

  return (
    <div className={styles.modalRoot}>
      <div className={styles.modal}>
        <div className={styles.modalContent}>{children}</div>
      </div>
    </div>
  )
}

export default Modal
