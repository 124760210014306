import React, { useState } from 'react'
import { Form, Input, Button, Checkbox, Modal } from 'sensd'
import * as styles from './index.module.less'
import classnames from 'classnames'

const PartnersForm = ({ onSubmit, form, status }) => {
  const [policy, setPolicy] = useState({
    url: '',
    title: '',
  })
  const [visible, setVisible] = useState(false)
  const onFinish = ({ protocol, ...values }: any) => {
    // 提交表单
    onSubmit?.(values)
  }
  // 校验失败
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  // 协议授权
  const handleClickPolicy = (link: string) => {
    return (e) => {
      e?.preventDefault()
      window.open(link, '_blank')
    }
  }
  const handleOpenPolicy = (link: string, title: string) => {
    return (e) => {
      e?.preventDefault()
      setPolicy({
        url: link,
        title: title,
      })
      setVisible(true)
    }
  }
  const hideModal = () => {
    setVisible(false)
  }

  return (
    <div>
      <Modal
        title={policy.title}
        visible={visible}
        size="large"
        onOk={hideModal}
        onCancel={hideModal}
        okText="确认"
        className="bg-[#F5FAFA]"
        cancelText
      >
        <div className="w-full  h-[480px]">
          <iframe className="w-full h-full overflow-y-scroll" title="readme" src={policy.url} />
        </div>
      </Modal>
      <div className={classnames('text-[#04cb94] font-semibold text-[20px] mb-[20px] ', styles.icon)}>
        欢迎您联系神策数据！
      </div>
      <Form form={form} name="partnersForm" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          name="name"
          rules={[{ required: true, message: '未填写' }]}
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input name="name" placeholder="姓名 *" />
        </Form.Item>
        <Form.Item
          name="company"
          rules={[{ required: true, message: '未填写' }]}
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input name="company" placeholder="公司 *" />
        </Form.Item>
        <Form.Item
          name="department"
          rules={[{ required: true, message: '未填写' }]}
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input name="position" placeholder="职位 *" />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            { required: true, message: '未填写' },
            {
              pattern: /^1\d{10}$/, // 以1开头，后面跟10位数字
              message: '请输入正确手机号',
            },
          ]}
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input name="phone" placeholder="手机 *" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: '请输入正确邮箱',
            },
            { required: true, message: '未填写' },
          ]}
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input name="email" placeholder="邮箱 *" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: '未填写' }]}
          name="contactPurpose"
          validateTrigger="onBlur"
          hideErrorWhenChange
        >
          <Input.TextArea rows={4} placeholder="联系目的 *" />
        </Form.Item>
        <Form.Item
          name="protocol"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject('未勾选')),
            },
          ]}
        >
          <Checkbox>
            <span className="text-[14px] ">
              <span onClick={handleOpenPolicy('/compliance/privacy.html', '神策隐私政策')} className="text-[#04cb94]">
                《隐私政策》
              </span>
              |
              <span onClick={handleOpenPolicy('/compliance/service.html', '用户服务协议')} className="text-[#04cb94]">
                《用户服务协议》
              </span>
            </span>
          </Checkbox>
        </Form.Item>
        <div className="mt-[4rem] lg:mt-[58px]">
          <Form.Item>
            <Button
              form="partnersForm"
              size="large"
              className="w-full bg-[#04CB94]"
              type="primary"
              htmlType="submit"
              disabled={status === 'loading'}
            >
              提交
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default PartnersForm
